<template>
  <div>
    <v-card>
      <v-card-text>
        <h2>ລາຍລະອຽດຂໍ້ມູນ</h2>
        <v-container>
            <v-row>
              <v-col>
                  <v-img v-if="news.image" :src=" `${news.image}`">
                  </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <h4>ລູກຄ້າ {{news.name}} {{news.surname}}</h4>
              </v-col>
              <v-col>ວັນທີ {{moment(news.created_at).format("DD:MM:YYYY")}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>{{news.title}}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <p>
                  {{news.message }}
                </p>
              </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Close()">
            Back
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      news: {},
      image: "",
      imageUrl: "",
    }
  },
  methods: {

    fetchNewDetail() {
      this.loading = true;
      this.$admin.get('request/' + this.$route.params.id)
          .then(res => {
            setTimeout(() => {
              this.loading = false;
              this.news = res.data.data;
            }, 300);
          }).catch(error => {
        this.loading = false;
        this.$store.commit("Toast_State", this.toast_error);
        if (error.response.status == 422) {
          const obj = error.response.data.errors;
          for (let [key, truck_user] of Object.entries(obj)) {
            this.server_errors[key] = truck_user[0];
          }
        }
      });
    },

    Close() {
      this.$router.go(-1);
    },
  },
  watch: {
  },
  created() {
    this.fetchNewDetail();
  }
};

</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>